.activitesMobile {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  padding-top: 16px;
}
.activitesMobile__cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: 390px;
  justify-content: center;
  grid-column-gap: 8px;
  padding-top: 25px;
  padding-bottom: 10px;
}
.activitesMobile__cards > * + * {
  margin-top: 70px;
}
.SpaVisuals {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activitiesInfoBanner {
  width: 100%;
  min-height: 60px;
  margin-top: 32px;
  padding: 16px;
  font-size: 20px;
  font-weight: 700;
  background-color: var(--color-info);
  display: flex;
  justify-content: center;
  align-items: center;
}

.activitiesInfoBanner a {
  text-decoration: underline;
  color: var(--color-primary);
}

@media screen and (min-width: 700px) and (max-width: 1324px) {
  .activitesMobile > p,
  .activitesMobile > div:nth-child(2) {
    text-align: center;
  }
}
@media screen and (min-width: 280px) and (max-width: 425px) {
  .activitesMobile__cards {
    grid-template-columns: repeat(auto-fill, 260px);
    grid-auto-rows: auto;
    grid-row-gap: 25px;
  }
}
