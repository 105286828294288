.activitiesWrapper {
  width: 78.125%;
  height: auto;
  flex: 1;
  padding-top: 16px;
}
.activitiesHeadline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.information {
  width: 100%;
  padding-bottom: 20px;
}
.information > * + * {
  margin-top: 1em;
}
.promotions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promotionCards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 3.5rem;
}
.promotionCards > * {
  margin-top: 2rem;
}
.activitesDisplay {
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 85px;
}
.acitivityFooter {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.information > *{
  white-space: pre-line
}

.activitiesInfoBanner {
  width: 100%;
  min-height: 60px;
  margin-top: 32px;
  padding: 16px;
  font-size: 20px;
  font-weight: 700;
  background-color: var(--color-info);
  display: flex;
  justify-content: center;
  align-items: center;
}

.activitiesInfoBanner a {
  text-decoration: underline;
  color: var(--color-primary);
}

@media screen and (min-width: 1000px) and (max-width: 1599px) {
  .activitiesWrapper {
    width: 75%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .activitiesWrapper {
    width: 70%;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .activitiesWrapper {
    width: 70%;
  }
}
@media screen and (min-width: 1325px) and (max-width: 1440px) {
  .activitiesWrapper {
    width: 75%;
  }
  .activitesDisplay {
    grid-gap: 0px;
    grid-row-gap: 50px;
  }
}
